export const Blog = () =>{
    return[{
        title:'Writing React code',
        date: '25-07-22',
        description:'Jocko Willink is an American author, podcaster, and retired naval officer who served in the Navy SEALs. His books include New York Times bestseller Extreme Ownership,  Discipline Equals Freedom a field manual, and his children’s book The Way of the Warrior Kid. Jocko also has one of the most successful podcasts out there so check out the Jocko Podcast.'
    },
    {
        title:'Solving Recurssion Problems',
        date: '26-07-22',
        description:'Jocko Willink is an American author, podcaster, and retired naval officer who served in the Navy SEALs. His books include New York Times bestseller Extreme Ownership,  Discipline Equals Freedom a field manual, and his children’s book The Way of the Warrior Kid. Jocko also has one of the most successful podcasts out there so check out the Jocko Podcast.'
    },
    {
        title:'doing Binary search',
        date: '27-07-22',
        description:'Jocko Willink is an American author, podcaster, and retired naval officer who served in the Navy SEALs. His books include New York Times bestseller Extreme Ownership,  Discipline Equals Freedom a field manual, and his children’s book The Way of the Warrior Kid. Jocko also has one of the most successful podcasts out there so check out the Jocko Podcast.'
    }
];
}